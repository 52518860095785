/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Trans } from 'react-i18next';
import Slider from '@appigram/react-rangeslider';

/* Internal imports */
import AbondementToggler from './AbondementToggler';
import AbondementUnilateralForm from './AbondementUnilateralForm';
import AddContributionLevel from './AddContributionLevel';
import Alert from "../../../../DS/feedback/Alert";
import Anciennete from './Anciennete';
import ContributionLine from './ContributionLine';
import YesInputWithoutReduxForm from '../../../../form/InputWithoutReduxForm';
import { LISTE_TAUX_ABONDEMENT } from '../../../../../services/TauxService';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesCheckboxV2 } from '../../../../form/CheckboxV2';
import { isPeiPercoiIndependantEnabled, isSourcesVersementAbondeesEnabled } from '../../../../../services/TenantService';
import { plafondAbondementPERCO } from '../../../../../modules/simulateur/fonctions';
import { sourcesVersementsPercoi } from '../../../../../services/SourcesVersementsService';

const AbondementPerCol = ({
  config,
  anciennete,
  showWarning,
  nbMaxPaliers,
  plafondPercoi,
  abnUnilateral,
  modeAnciennete,
  addPalierPercoi,
  changerTauxPercoi,
  palierPercoiActive,
  plafondEmptyError,
  checkPlafondPercoi,
  checkTauxDegressif,
  classDisablePercoi,
  deletePalierPercoi,
  plafondAbnUnilateral,
  dispositifAnciennete,
  showPlafondPassPercoi,
  getPeiOuPercoiChecked,
  changerValeurMaxPalier,
  palierAbondementsPercoi,
  classDisablePercoiSlider,
  handleManualChangePlafond,
  changerValeurPlafondPercoi,
  classDisableOuverturePercoi,
  miseEnPlaceAbondementPercoi,
  errorMaxPalierSuperieurAMin,
  checkMaxPalierSuperieurAMin,
  getPalierAbondementMaxPercoi,
  changerValeurSliderPlafondPercoi,
  checkVersementVolontaireSelectionnee,
  desactiverMiseEnPlaceAbondementPercoi,
  checkSourceVersementPercoiSelectionnee,
  validateSourceVersementPercoiSelectionnee,
  PASS
}) => {
  const handleChangeSlider = (value) => {
    handleManualChangePlafond(value);
    !palierPercoiActive && changerValeurSliderPlafondPercoi(value);
  };

  const handleChangeInput = (e) => {
    handleManualChangePlafond(e.target.value);
    changerValeurPlafondPercoi(e);
  };

  return (
    <div className="cell">
      <div className="yes-rectangle">
        <div className="premierNiveauAbondement checkboxMiseEnPlaceAbondement">
          <Field
            component={YesCheckboxV2}
            type="checkbox"
            name="ouverturePercoi"
            id="ouverturePercoi"
            validate={[getPeiOuPercoiChecked]}
            labeltext={<TransHtmlText i18nKey="dispositif.ouverture.percoi.titre" params={{ config: config }} />}
            disabled={!isPeiPercoiIndependantEnabled()}
            onChange={desactiverMiseEnPlaceAbondementPercoi}
          />
        </div>
        <div className="contentAbondementActive">
          <div className="abondement-unilateral">
            <AbondementUnilateralForm
              showContent={!classDisableOuverturePercoi && abnUnilateral}
              disabled={classDisableOuverturePercoi}
              config={config}
              PASS={PASS}
            />
          </div>
          <AbondementToggler
            config={config}
            classDisableOuverture={classDisableOuverturePercoi}
            name="miseEnPlaceAbondementPercoi"
          />

          <div className={`alignContentInColumn ${classDisablePercoi} ${miseEnPlaceAbondementPercoi ? '' : 'hide'}`}>
            <div className="parametrageAbondement">Paramétrage de l'abondement</div>
            {dispositifAnciennete.percol ? (
              <>
                <Anciennete type="percol" anciennete={anciennete} onCancel={() => modeAnciennete('percol', false)} PASS={PASS}/>
              </>
            ) : palierAbondementsPercoi && palierAbondementsPercoi.length > 1 ? (
              palierAbondementsPercoi.map((_, i) => (
                <ContributionLine
                  index={i}
                  key={`palierAbondementsPercoi-${i}`}
                  contributionType="palierAbondementsPercoi"
                  deletable={palierAbondementsPercoi.length - 1 === i && palierPercoiActive}
                  deleteLine={() => deletePalierPercoi(false)}
                  addLine={addPalierPercoi}
                  changeRate={(e) => changerTauxPercoi(e, i, 'false')}
                  isActive={palierPercoiActive}
                  checkMaxValue={checkPlafondPercoi}
                  checkMaxHigherThanMin={checkMaxPalierSuperieurAMin}
                  changeMaxValue={(e) => changerValeurMaxPalier(e, i, 'Percoi')}
                  maxStepValue={getPalierAbondementMaxPercoi}
                  checkDecliningRate={() => checkTauxDegressif(palierAbondementsPercoi)}
                  doValidation={miseEnPlaceAbondementPercoi}
                  listTauxAbondement={LISTE_TAUX_ABONDEMENT}
                />
              ))
            ) : (
              <ContributionLine
                contributionType="palierAbondementsPercoi"
                index={0}
                key={'palierAbondementsPercoi-0'}
                deletable={false}
                addLine={addPalierPercoi}
                changeRate={(e) => changerTauxPercoi(e, 0, false)}
                isActive={palierPercoiActive}
                checkMaxValue={checkPlafondPercoi}
                checkMaxHigherThanMin={checkMaxPalierSuperieurAMin}
                changeMaxValue={(e) => changerValeurMaxPalier(e, 0, 'Percoi')}
                maxStepValue={getPalierAbondementMaxPercoi}
                checkDecliningRate={() => checkTauxDegressif(palierAbondementsPercoi)}
                modeAnciennete={modeAnciennete}
                doValidation={miseEnPlaceAbondementPercoi}
                listTauxAbondement={LISTE_TAUX_ABONDEMENT}
              />
            )}

            {+plafondPercoi + (abnUnilateral ? plafondAbnUnilateral : 0) >= plafondAbondementPERCO() && palierPercoiActive && (
              <span className="errorMsgTauxDegressif">
                Veuillez modifier vos paliers de versement, l’abondement ne peut pas excéder 16% du PASS
              </span>
            )}
            {checkTauxDegressif(palierAbondementsPercoi) && (
              <span className="errorMsgTauxDegressif">Attention, le pourcentage du taux d’abondement doit être dégressif.</span>
            )}
            {palierPercoiActive && (
              <span className="errorMsgTauxDegressif">{errorMaxPalierSuperieurAMin(palierAbondementsPercoi)}</span>
            )}
            {palierPercoiActive &&
              palierAbondementsPercoi &&
              palierAbondementsPercoi.length > 1 &&
              palierAbondementsPercoi.length < nbMaxPaliers && (
                <AddContributionLevel
                  handleAdd={() => addPalierPercoi()}
                  contributionTypeLabel="abondement"
                  additionnalClass="boutonAjouterPalierADroite"
                />
              )}
            {!dispositifAnciennete.percol && (
              <div className="alignContentInRow">
                <div className="yes-label">Plafond d’abondement (max 16% du PASS)</div>
                <div className={`cell sliderDispositifAbondement ${classDisablePercoiSlider}`}>
                  <Slider
                    value={plafondPercoi}
                    orientation="horizontal"
                    onChange={(value) => handleChangeSlider(value)}
                    tooltip={false}
                    max={plafondAbondementPERCO(abnUnilateral ? plafondAbnUnilateral : undefined)}
                  />
                  {`${(plafondPercoi * 100 / PASS).toFixed(2)} %`}
                </div>
                <div className={`plafondAbondementInput${classDisablePercoi}`}>
                  <YesInputWithoutReduxForm
                    disabled={palierPercoiActive}
                    value={plafondPercoi}
                    onChange={(e) => handleChangeInput(e)}
                    euroField={true}
                  />
                  {!palierPercoiActive && showPlafondPassPercoi && <span className="infosAbondementPass">Plafond atteint</span>}
                </div>
              </div>
            )}
            <div className="errorMsg">{plafondEmptyError}</div>
            {showWarning && (
              <div className="warning" style={{ color: '#e95730', fontSize: '.8rem' }}>
                <Trans i18nKey="dispositif.ouverture.percoi.warning" />
              </div>
            )}
            {miseEnPlaceAbondementPercoi && isSourcesVersementAbondeesEnabled() && (
              <div className="alignContentInColumn">
                <div className="parametrageAbondement">Sélectionnez les sources de versement abondées</div>
                <div className="alignContentInRow">
                  {sourcesVersementsPercoi.map((source, i) => (
                    <div
                      key={`percol.${source.name}`}
                      className={`${dispositifAnciennete.percol && i !== 0 ? 'disabled ' : ''}sourcesVersementAbondees`}
                    >
                      <Field
                        disabled={dispositifAnciennete.percol && i !== 0}
                        component={YesCheckboxV2}
                        type="checkbox"
                        name={`sourcesVersementsPercoi.${source.name}`}
                        id={`sourcesVersementsPercoi.${source.name}`}
                        validate={validateSourceVersementPercoiSelectionnee}
                        labeltext={source.label}
                      />
                    </div>
                  ))}
                </div>
                <div className="errorMsgTauxDegressif">{checkSourceVersementPercoiSelectionnee()}</div>
                {dispositifAnciennete.percol && <div className="warningMsg">{checkVersementVolontaireSelectionnee('Percoi')}</div>}
              </div>
            )}
          </div>
        </div>
        {miseEnPlaceAbondementPercoi && !isSourcesVersementAbondeesEnabled() && (
          <div className={`cell medium-12 infosAbondementSources ${classDisablePercoi}`}>
            Toutes les sources de versement seront abondées
          </div>
        )}
      </div>
    </div>
  );
};

AbondementPerCol.propTypes = {
  config: PropTypes.object,
  showWarning: PropTypes.bool,
  anciennete: PropTypes.object,
  nbMaxPaliers: PropTypes.number,
  plafondPercoi: PropTypes.number,
  addPalierPercoi: PropTypes.func,
  palierPercoiActive: PropTypes.bool,
  checkPlafondPercoi: PropTypes.func,
  deletePalierPercoi: PropTypes.func,
  maxAvailablePercol: PropTypes.number,
  classDisablePercoi: PropTypes.string,
  getPeiOuPercoiChecked: PropTypes.func,
  showPlafondPassPercoi: PropTypes.func,
  changerValeurMaxPalier: PropTypes.func,
  dispositifAnciennete: PropTypes.object,
  sourcesVersementsPercoi: PropTypes.array,
  palierAbondementsPercoi: PropTypes.array,
  checkTauxDegressifPercoi: PropTypes.func,
  classDisablePercoiSlider: PropTypes.string,
  changerValeurPlafondPercoi: PropTypes.func,
  modeAnciennete: PropTypes.func.isRequired,
  miseEnPlaceAbondementPercoi: PropTypes.bool,
  checkMaxPalierSuperieurAMin: PropTypes.func,
  errorMaxPalierSuperieurAMin: PropTypes.func,
  getPalierAbondementMaxPercoi: PropTypes.func,
  changerTauxPercoi: PropTypes.func.isRequired,
  classDisableOuverturePercoi: PropTypes.string,
  changerValeurSliderPlafondPercoi: PropTypes.func,
  checkVersementVolontaireSelectionnee: PropTypes.func,
  desactiverMiseEnPlaceAbondementPercoi: PropTypes.func,
  checkSourceVersementPercoiSelectionnee: PropTypes.func,
  validateSourceVersementPercoiSelectionnee: PropTypes.func,
  PASS: PropTypes.number
};

export default AbondementPerCol;
