const SIMULATEUR_URL = '/simulateur';

export default {
  // Nom de domaine du site statique
  siteUrl: process.env.REACT_APP_STATIC_URL,

  mentionsLegalesUrl: process.env.REACT_APP_STATIC_URL + '/legal/politique-confidentialite',

  infosReglementairesUrl: process.env.REACT_APP_STATIC_URL + '/legal/informations-reglementaires',

  societeComUrl: 'https://www.societe.com/',

  peeUrl: process.env.REACT_APP_STATIC_URL + '/guide/plans/pee',

  percoUrl: process.env.REACT_APP_STATIC_URL + '/guide/plans/perco',

  gesPeeUrl: process.env.REACT_APP_STATIC_URL + '/epargne-salariale/apprendre/dispositif#PEE',

  gesPercoUrl: process.env.REACT_APP_STATIC_URL + '/epargne-salariale/apprendre/dispositif#PER-COL',

  interessementUrl: process.env.REACT_APP_STATIC_URL + '/guide/sources/interessement',

  participationUrl: process.env.REACT_APP_STATIC_URL + '/guide/sources/participation',

  gesInteressementUrl:
    process.env.REACT_APP_STATIC_URL + '/epargne-salariale/apprendre/sources-alimentation#Interessement',

  gesParticipationUrl:
    process.env.REACT_APP_STATIC_URL + '/epargne-salariale/apprendre/sources-alimentation#Participation',

  gesAbondementUrl:
    process.env.REACT_APP_STATIC_URL + '/epargne-salariale/apprendre/sources-alimentation',

  gesRegimePPVUrl:
    process.env.REACT_APP_STATIC_URL + '/bibliotheque/pdf-viewer/Regime_Fiscal_Social_PPV',

  gesBoiteAOutilsEpargneUrl: process.env.REACT_APP_STATIC_URL + '/epargne-salariale/bibliotheque/outils',

  gesSimulateurEs: process.env.REACT_APP_STATIC_URL + '/epargne-salariale/avant-de-souscrire/simulateur-es',

  gesIPlus: process.env.REACT_APP_STATIC_URL + '/epargne-salariale/avant-de-souscrire/generateur-interessement',

  yesSimulateurEs: SIMULATEUR_URL,

  salarieMinimumUrl:
    process.env.REACT_APP_STATIC_URL +
    '/faq/souscription#Quel-est-leffectif-minimum-dune-entreprise-pour-souscrire-a-lepargne-salariale',

  passUrl: process.env.REACT_APP_STATIC_URL + '/guide/plans/pee#Quest-ce-que-le-Plafond-Annuel-de-la-Securite-Sociale-PASS',

  yesAbondementUrl: process.env.REACT_APP_STATIC_URL + '/guide/sources/abondement',

  interetPercoiUrl:
    process.env.REACT_APP_STATIC_URL +
    '/faq/pei-percoi#Mes-salaries-beneficient-deja-dun-PEEPEI-quel-est-linteret-de-mettre-en-place-un-PERCOPERCOI',

  grilleTarifaireUrl: 'https://epargnesalariale.yomoni.fr/ressources/Grille_tarifaire.pdf',

  listeTarificationUrl: 'https://epargnesalariale.yomoni.fr/ressources/Liste_de_ratification.pdf',

  documentInformationSalariesUrl: 'https://epargnesalariale.yomoni.fr/ressources/Guide_epargnants.pdf',

  extraitPeiPercoiUrl: 'https://epargnesalariale.yomoni.fr/ressources/Extrait_PEI-PERCOI.pdf',

  gesAccordParticipation: process.env.REACT_APP_STATIC_URL + '/ressources/Guide_participation.pdf',

  gesAccordInteressement: process.env.REACT_APP_STATIC_URL + '/ressources/Guide_interessement.pdf',
  // URL de la page d'accès au tunnel'
  tunnelUrl: '/creationCompte',

  // Description par défaut utilisé pour les metas SEO et opengraph,
  // il est possible de la surcharger dans les pages
  description:
    'Yomoni est une solution simple pour faire gérer votre épargne par des experts. Assurance vie en ligne, PEA ou compte-titres, profitez des meilleurs tarifs.',

  // Titre par utilisé pour nommer la fenêtre du navigateur et dans les meta SEO et opengraph,
  // il est recommander de la surcharger dans les pages
  title: 'Placez vos économies dans déplacer des montages | Yomoni',

  // Entrée dans la barre de navigation
  navbar: {
    // Détermine s'il faut afficher le bouton de connexion
    enableConnectButton: true,

    // Détermine l'alignement des items du menu
    itemsPosition: 'left',

    // Listes des items collés à gauche à coté du logo Yomoni
    leftItems: [
      {
        title: 'Vos besoins',
        url: process.env.REACT_APP_STATIC_URL + '/vos-besoins'
      },
      {
        title: 'Notre solution',
        url: process.env.REACT_APP_STATIC_URL + '/notre-solution'
      },
      {
        title: 'Le guide',
        url: process.env.REACT_APP_STATIC_URL + '/epargne-salariale/apprendre/comprendre'
      },
      {
        title: 'Notre gestion',
        url: 'https://epargnesalariale.yomoni.fr/notre-gestion'
      },
      {
        title: 'Simulateur',
        url: SIMULATEUR_URL
      }
    ]

    // Liste des items collés à droite, à coté du lien "Se connecter"
    //rightItems: {}
  },

  // Informations de contact
  contact: {
    address: {
      street: '19 rue Réaumur',
      city: '75003 Paris'
    },
    telNumber: {
      label: '01 75 85 02 74',
      value: '+33175850274'
    },
    emailAddress: 'epargnesalariale@yomoni.fr'
  },

  footerLinks: {
    besoins: [
      {
        title: 'Un dispositif gagnant-gagnant',
        url: process.env.REACT_APP_STATIC_URL + '/vos-besoins#anchor-dispositif-gagnant-gagnant'
      },
      {
        title: 'Un exemple chiffré',
        url: process.env.REACT_APP_STATIC_URL + '/vos-besoins#anchor-exemple-chiffre'
      }
    ],
    solution: [
      {
        title: 'Mise en place des plans',
        url: process.env.REACT_APP_STATIC_URL + '/notre-solution#anchor-mise-place-plans'
      },
      {
        title: 'Paramétrage des dispositifs',
        url: process.env.REACT_APP_STATIC_URL + '/notre-solution#anchor-parametrage-dispositifs'
      },
      {
        title: 'Gestion des fonds',
        url: process.env.REACT_APP_STATIC_URL + '/notre-solution#anchor-gestion-fonds'
      },
      {
        title: 'La valeur ajoutée de Yomoni',
        url: process.env.REACT_APP_STATIC_URL + '/notre-solution#anchor-valeur-ajoutee'
      },
      {
        title: 'Des tarifs sans surprise',
        url: process.env.REACT_APP_STATIC_URL + '/notre-solution#anchor-sans-surprise'
      }
    ],
    guide: [
      {
        title: 'Les plans',
        url: ''
      },
      {
        title: 'PEE',
        url: process.env.REACT_APP_STATIC_URL + '/guide/plans/pee'
      },
      {
        title: 'PERCO',
        url: process.env.REACT_APP_STATIC_URL + '/guide/plans/perco'
      },
      {
        title: 'Les dispositifs',
        url: ''
      },
      {
        title: 'Abondement',
        url: process.env.REACT_APP_STATIC_URL + '/guide/sources/abondement'
      },
      {
        title: 'Versements volontaires',
        url: process.env.REACT_APP_STATIC_URL + '/guide/sources/versements-volontaires'
      },
      {
        title: 'Intéressement',
        url: process.env.REACT_APP_STATIC_URL + '/guide/sources/interessement'
      },
      {
        title: 'Participation',
        url: process.env.REACT_APP_STATIC_URL + '/guide/sources/participation'
      },
      {
        title: 'Versement des jours CET',
        url: process.env.REACT_APP_STATIC_URL + '/guide/sources/versement-jours-cet'
      },
      {
        title: 'Le simulateur',
        url: SIMULATEUR_URL
      }
    ],
    juridique: [
      {
        title: 'Politique de confidentialité',
        url: process.env.REACT_APP_STATIC_URL + '/legal/politique-confidentialite'
      },
      {
        title: 'Informations réglementaires',
        url: process.env.REACT_APP_STATIC_URL + '/legal/informations-reglementaires'
      },
      {
        title: 'Notre gestion',
        url: process.env.REACT_APP_STATIC_URL + '/notre-gestion'
      }
    ],
    apropos: [
      {
        title: 'FAQ',
        url: process.env.REACT_APP_STATIC_URL + '/faq/yomoni-epargne-salariale'
      },
      {
        title: 'Équipe',
        url: 'https://www.yomoni.fr/a-propos/equipe'
      },
      {
        title: 'Blog',
        url: 'https://www.yomoni.fr/blog'
      },
      {
        title: 'Jobs',
        url: 'https://www.welcometothejungle.co/companies/yomoni'
      }
    ]
  },

  // Liens sociaux, utilisés dans le footer
  socialLinks: {
    facebook: 'https://www.facebook.com/Yomoni.fr',
    twitter: 'https://twitter.com/yomoni_fr',
    twitterAccount: '@yomoni_fr',
    instagram: 'https://www.instagram.com/yomoni.fr/',
    linkedin: 'https://www.linkedin.com/company/yomoni',
    googlePlus: 'https://plus.google.com/111789116914386428236'
  },
  // Identifiants de tracking GTM, dépend de l'environnement
  tracking: {
    googleTagManagerId: 'GTM-MC74SM6'
  },

  mailChimp: {
    // URL mail-chimp d'abonnement à la newsletter dans le footer
    subscribeNewsletter: '//yomoni.us11.list-manage.com/subscribe/post?u=00df9bda0c07d4c06f76586d9&amp;id=19af08f4d1',
    // URL mail-chimp de collecte des prospects épargne salariale
    subscribeProspect: '//yomoni.us11.list-manage.com/subscribe/post?u=00df9bda0c07d4c06f76586d9&amp;id=dbc4975f14'
  },

  copyright: '©Yomoni | Tous droits réservés',
  disclaimer:
    'Yomoni est une société de gestion de portefeuille agréée par l’Autorité des marchés financiers (AMF) sous le n°GP-15000014 et courtier en assurance, n°ORIAS 15003517.'
};
