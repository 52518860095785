/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import '@appigram/react-rangeslider/lib/index.css';
import Box from '@mui/material/Box';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';

/* Internal imports */
import AbondementPEI from './AbondementPEI';
import AbondementPerCol from './AbondementPerCol';
import AncienneteMinimum from './AncienneteMinimum';
import CircularProgress from '../../../../DS/feedback/Progress';
import Periodicite from './Periodicite';
import config from '../../../../../common/StaticSiteConfig';
import styles from '../../../../DS/inputs/File/FileStyles';
import { ANCIENNETE_MINIMUM, PERIODICITE_OPTIONS } from '../../../../../utils/constantes/US/DISPOSITIFABONDEMENT';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesButton } from '../../../../form/Button';
import { isPeiPercoiIndependantEnabled } from '../../../../../services/TenantService';
import { nbMaxPaliers } from '../../../../../services/TauxService';
import { nombre, nombreSansEspaces } from '../../../../../common/FunctionsUtils';
import { plafondAbondementPEE, plafondAbondementPERCO } from '../../../../../modules/simulateur/fonctions';
import { sourcesVersementsPei } from '../../../../../services/SourcesVersementsService';
import {CEILING_PPV} from '../../../../../common/constants';

let isAnciennetePeiMode = false;
let isAnciennetePercolMode = false;

const getPeiOuPercoiChecked = (value, allValues, props) => {
  return !(props.ouverturePei || props.ouverturePercoi);
};

const validateSourceVersementPeiSelectionnee = (value, allValues, props) =>
  props.sourcesVersementsPei && Object.values(props.sourcesVersementsPei).some((source) => source)
    ? undefined
    : 'Sélectionnez au moins une source de versement';

const validate = (values) => {
  const errors = {};
  if (
    values.ouverturePei &&
    values.palierAbondementsPei.length === 1 &&
    values.palierAbondementsPei[0].versementMax <= 0 &&
    !isAnciennetePeiMode
  ) {
    errors.plafondPei = 'Le plafond PEI ne doit pas être nul';
  }
  if (
    values.ouverturePercoi &&
    values.palierAbondementsPercoi.length === 1 &&
    values.palierAbondementsPercoi[0].versementMax <= 0 &&
    !isAnciennetePercolMode &&
    (values.abnUnilateral || values.plafondAbnUnilateral === 0)
  ) {
    errors.plafondPercoi = 'Le plafond PERCOL(i) ne doit pas être nul';
  }
  if (!values.periodicite || !values.periodicite.option || values.periodicite.option === '0') {
    errors.periodicite = 'Vous devez selectionner une périodicité';
  }
  if (
    values.periodicite &&
    values.periodicite.option === '2' &&
    (!values.periodicite.trimestre || values.periodicite.trimestre === '0')
  ) {
    errors.periodicite = 'Vous devez selectionner un trimestre';
  }

  return errors;
};

const validateSourceVersementPercoiSelectionnee = (value, allValues, props) =>
  props.sourcesVersementsPercoi && Object.values(props.sourcesVersementsPercoi).some((source) => source) ? undefined : true;

const getAncienneteMinimumDefaultValue = () => (ANCIENNETE_MINIMUM[3].value);

const getPeriodiciteOptionDefaultValue = () => (PERIODICITE_OPTIONS[1].value);

class DispositifAbondementForm extends Component {
  constructor(props) {
    super(props);

    this.changerValeurPlafondPei = this.changerValeurPlafondPei.bind(this);
    this.changerValeurSliderPlafondPei = this.changerValeurSliderPlafondPei.bind(this);
    this.changerValeurPlafondPercoi = this.changerValeurPlafondPercoi.bind(this);
    this.changerValeurSliderPlafondPercoi = this.changerValeurSliderPlafondPercoi.bind(this);
    this.desactiverMiseEnPlaceAbondementPei = this.desactiverMiseEnPlaceAbondementPei.bind(this);
    this.desactiverMiseEnPlaceAbondementPercoi = this.desactiverMiseEnPlaceAbondementPercoi.bind(this);

    this.state = {
      showPlafondPassPei: false,
      showPlafondPassPercoi: false,
      showWarning: false,
      manualPlafondPercoi: 2000
    };
  }

  componentDidMount() {
    isAnciennetePeiMode = !!this.props.dispositifAnciennete.pei;
    isAnciennetePercolMode = !!this.props.dispositifAnciennete.percol;
    this.props.change('ouverturePei', false);
    this.props.change(`palierAbondementsPei[${0}].taux`, 1);
    this.props.change(`palierAbondementsPei[${0}].versementMin`, 0);
    this.props.change(`palierAbondementsPei[${0}].versementMax`, 1000);
    this.props.change('anciennete.pei.taux', 0.5);

    this.props.change('ouverturePercoi', false);
    this.props.change(`palierAbondementsPercoi[${0}].taux`, 1);
    this.props.change(`palierAbondementsPercoi[${0}].versementMin`, 0);
    this.props.change(`palierAbondementsPercoi[${0}].versementMax`, 2000);
    this.props.change('anciennete.percol.taux', 0.5);

    this.props.change('plafondPei', 1000);
    this.props.change('plafondPercoi', 2000);
    this.props.change('miseEnPlaceAbondementPei', false);
    this.props.change('miseEnPlaceAbondementPercoi', false);

    this.props.change('anciennete.pei.plafond1an', 1);
    this.props.change('anciennete.percol.plafond1an', 1);

    // On set les valeurs persistées s'ils elles existent
    if (this.props.tunnel.dossier.dispositifs) {
      const dispositifs = this.props.tunnel.dossier.dispositifs;
      this.props.change(
        'ancienneteMinimum',
        !dispositifs.anciennete ? getAncienneteMinimumDefaultValue().toString() : dispositifs.anciennete && dispositifs.anciennete.toString()
      );
      if (dispositifs.periodicite) {
        this.props.change(
          'periodicite.option',
          !dispositifs.periodicite.option ? getPeriodiciteOptionDefaultValue().toString() : dispositifs.periodicite.option.toString()
        );
        this.props.change(
          'periodicite.trimestre',
          dispositifs.periodicite.trimestre ? dispositifs.periodicite.trimestre.toString() : null
        );
      }
      if (dispositifs.dispositifPei.ouverture !== this.props.ouverturePei) {
        this.props.change('ouverturePei', dispositifs.dispositifPei.ouverture);

        if (dispositifs.dispositifPei.abondement) {
          this.props.change(
            'sourcesVersementsPei',
            this.lireSourcesVersements(dispositifs.dispositifPei.abondement.sourceVersements)
          );
          this.props.change('miseEnPlaceAbondementPei', true);
          if (
            dispositifs.dispositifPei.abondement.palierAbondements &&
            dispositifs.dispositifPei.abondement.palierAbondements.length >= 1
          ) {
            this.props.change('palierAbondementsPei', dispositifs.dispositifPei.abondement.palierAbondements);
          }
          if (dispositifs.dispositifPei.abondement.palierAncienneteAbondements) {
            this.props.change('anciennete.pei.taux', dispositifs.dispositifPei.abondement.palierAncienneteAbondements.taux);
            this.props.change('anciennete.pei.taux', dispositifs.dispositifPei.abondement.palierAncienneteAbondements.taux);
            this.props.change(
              'anciennete.pei.plafond1an',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafond1an
            );
            this.props.change(
              'anciennete.pei.plafond3an',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafond3an
            );
            this.props.change(
              'anciennete.pei.plafond5an',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafond5an
            );
            this.props.change(
              'anciennete.pei.plafondMax',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafondMax
            );
          }

          if (dispositifs.dispositifPei.abondement.plafondPass) {
            this.props.change('plafondPei', plafondAbondementPEE());
            this.setState({
              showPlafondPassPei: true
            });
          } else {
            this.props.change(
              'plafondPei',
              this.calculerAbondementMaxDesPaliers(dispositifs.dispositifPei.abondement.palierAbondements)
            );
          }
        }
      }

      // PERCOI
      if (dispositifs.dispositifPercoi.ouverture !== this.props.ouverturePercoi) {
        this.props.change('ouverturePercoi', dispositifs.dispositifPercoi.ouverture);

        if (dispositifs.dispositifPercoi.abondement) {
          this.props.change('miseEnPlaceAbondementPercoi', true);
          this.props.change(
            'sourcesVersementsPercoi',
            this.lireSourcesVersements(dispositifs.dispositifPercoi.abondement.sourceVersements)
          );
          if (
            dispositifs.dispositifPercoi.abondement.palierAbondements &&
            dispositifs.dispositifPercoi.abondement.palierAbondements.length >= 1
          ) {
            this.props.change('palierAbondementsPercoi', dispositifs.dispositifPercoi.abondement.palierAbondements);
          }
          if (dispositifs.dispositifPercoi.abondement.palierAncienneteAbondements) {
            this.props.change('anciennete.percol.taux', dispositifs.dispositifPercoi.abondement.palierAncienneteAbondements.taux);
            this.props.change('anciennete.percol.taux', dispositifs.dispositifPercoi.abondement.palierAncienneteAbondements.taux);
            this.props.change(
              'anciennete.percol.plafond1an',
              dispositifs.dispositifPercoi.abondement.palierAncienneteAbondements.plafond1an
            );
            this.props.change(
              'anciennete.percol.plafond3an',
              dispositifs.dispositifPercoi.abondement.palierAncienneteAbondements.plafond3an
            );
            this.props.change(
              'anciennete.percol.plafond5an',
              dispositifs.dispositifPercoi.abondement.palierAncienneteAbondements.plafond5an
            );
            this.props.change(
              'anciennete.percol.plafondMax',
              dispositifs.dispositifPercoi.abondement.palierAncienneteAbondements.plafondMax
            );
          }

          if (dispositifs.dispositifPercoi.abondement.plafondPass) {
            this.props.change('plafondPercoi', plafondAbondementPERCO());
            this.setState({
              showPlafondPassPercoi: true,
              manualPlafondPercoi: plafondAbondementPERCO()
            });
          } else {
            this.props.change(
              'plafondPercoi',
              this.calculerAbondementMaxDesPaliers(dispositifs.dispositifPercoi.abondement.palierAbondements)
            );
            this.setState({
              manualPlafondPercoi: this.calculerAbondementMaxDesPaliers(dispositifs.dispositifPercoi.abondement.palierAbondements)
            });
          }
        }
        if (dispositifs.dispositifPercoi.abondementUnilateral) {
          this.props.change('plafondAbnUnilateral', dispositifs.dispositifPercoi.abondementUnilateral.plafond || 0);
          this.props.change('abnUnilateral', dispositifs.dispositifPercoi.abondementUnilateral.miseEnPlace);
        }
      }
    }

    this.warningTimeout = undefined;
  }

  componentDidUpdate(prevProps, prevState) {
    isAnciennetePeiMode = !!this.props.dispositifAnciennete.pei;
    isAnciennetePercolMode = !!this.props.dispositifAnciennete.percol;
    let showWarning = false;
    if (
      this.props.plafondPercoi !== prevProps.plafondPercoi ||
      this.props.abnUnilateral !== prevProps.abnUnilateral ||
      this.props.plafondAbnUnilateral !== prevProps.plafondAbnUnilateral
    ) {
      if (this.props.plafondPercoi > this.getMaxPerco() && this.props.palierAbondementsPercoi.length <= 1) {
        this.props.change('plafondPercoi', this.getMaxPerco());
        showWarning = true;
      }
      // Le plafond Percol(i) est remis à la dernière valeur entrée manuellement si
      // on diminue le montant de l'abondement unilatéral après une réduction automatique
      // du plafond Percol(i) suite à un dépassement du PASS
      if (this.props.plafondAbnUnilateral < prevProps.plafondAbnUnilateral) {
        if (this.props.plafondPercoi < +this.state.manualPlafondPercoi) {
          const diff = prevProps.plafondAbnUnilateral - this.props.plafondAbnUnilateral;
          if (diff + +this.state.manualPlafondPercoi > this.getMaxPerco()) {
            this.props.change('plafondPercoi', +this.state.manualPlafondPercoi);
          } else {
            this.props.change('plafondPercoi', this.props.plafondPercoi + diff);
          }
        }
      }
      // mise à jour du plafond Percol(i) à la dernière valeur entrée manuellement si
      // on déselectionne l'abondement unilatéral après qu'il est provoqué une mise à
      // jour automatique suite à un dépassement du PASS
      if (prevProps.abnUnilateral && !this.props.abnUnilateral && this.props.plafondPercoi !== +this.state.manualPlafondPercoi) {
        this.props.change('plafondPercoi', +this.state.manualPlafondPercoi);
      }
      if (this.props.anciennete && this.props.anciennete.percol) {
        const max = this.getMaxPerco();
        if (this.props.anciennete.percol.plafond1an > max) {
          this.props.change('anciennete.percol.plafond1an', max);
          showWarning = true;
        }
        if (this.props.anciennete.percol.plafond3an > max) {
          this.props.change('anciennete.percol.plafond3an', max);
          showWarning = true;
        }
        if (this.props.anciennete.percol.plafond5an > max) {
          this.props.change('anciennete.percol.plafond5an', max);
          showWarning = true;
        }
        if (this.props.anciennete.percol.plafondMax > max) {
          this.props.change('anciennete.percol.plafondMax', max);
          showWarning = true;
        }
      }
    }
    if (prevProps.dispositifAnciennete !== this.props.dispositifAnciennete) {
      if (!this.props.plafondPei && this.props.palierAbondementsPei && !this.props.dispositifAnciennete.pei) {
        this.props.change('plafondPei', this.props.palierAbondementsPei[0].versementMax || 1000);
        if (!this.props.palierAbondementsPei[0].versementMax) {
          this.props.change('palierAbondementsPei[0].versementMax', 1000);
        }
      }
      if (!this.props.plafondPercoi && this.props.palierAbondementsPercoi && !this.props.dispositifAnciennete.percol) {
        this.props.change('plafondPercoi', this.props.palierAbondementsPercoi[0].versementMax || 2000);
        if (!this.props.palierAbondementsPercoi[0].versementMax) {
          this.props.change('palierAbondementsPercoi[0].versementMax', 2000);
        }
      }
    }
    if (showWarning) {
      this.displayWarning();
    }

    if (this.props.dispositifAnciennete.pei) {
      this.props.change('sourcesVersementsPei', { [sourcesVersementsPei[0].name]: true });
    }

    if (this.props.dispositifAnciennete.percol) {
      this.props.change('sourcesVersementsPercoi', { [sourcesVersementsPei[0].name]: true });
    }
  }

  componentWillUnmount() {
    this.warningTimeout = undefined;
  }

  displayWarning = () => {
    this.setState({ showWarning: true });
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
    this.warningTimeout = setTimeout(() => this.setState({ showWarning: false }), 5000);
  };

  //lecture du tableau des sources de versements pour transformation en objet
  lireSourcesVersements = (sources) =>
    sources && sources.reduce((sourcesVersements, source) => ({ ...sourcesVersements, [source]: true }), {});

  desactiverMiseEnPlaceAbondementPei = (event) => {
    if (event.target.value && isPeiPercoiIndependantEnabled()) {
      this.props.change('miseEnPlaceAbondementPei', false);
    }
  };

  desactiverMiseEnPlaceAbondementPercoi = (event) => {
    if (event.target.value) {
      this.props.change('miseEnPlaceAbondementPercoi', false);
      this.props.change('abnUnilateral', false);
    }
  };

  checkPeiOuPercoiChecked = () => {
    return this.props.ouverturePei || this.props.ouverturePercoi;
  };

  /***
   * On update la valeur totale de l'abondement seulement s'il a plus d'un palier
   * @param event
   * @param index
   */
  changerTauxPei = (event, index) => {
    if (this.props.palierAbondementsPei.length > 1) {
      let paliers = this.props.palierAbondementsPei;
      if (event.target.validity.valid) {
        let tauxPei = event.target.value;
        if (event.target.value === '') {
          tauxPei = 1;
        }
        paliers[index].taux = tauxPei;
        this.props.change(`palierAbondementsPei[${index}].taux`, tauxPei);

        this.changerValeurSliderPlafondPei(this.calculerAbondementMaxDesPaliers(paliers));
      }
    }
  };

  /***
   * On update la valeur totale de l'abondement seulement s'il a plus d'un palier
   * @param event
   * @param index
   */
  changerTauxPercoi = (event, index) => {
    if (this.props.palierAbondementsPercoi.length > 1) {
      let paliers = this.props.palierAbondementsPercoi;
      if (event.target.validity.valid) {
        let tauxPercoi = event.target.value;
        if (event.target.value === '') {
          tauxPercoi = 1;
        }
        paliers[index].taux = tauxPercoi;
        this.props.change(`palierAbondementsPercoi[${index}].taux`, tauxPercoi);

        this.changerValeurSliderPlafondPercoi(this.calculerAbondementMaxDesPaliers(paliers));
      }
    }
  };

  changerValeurMaxPalier = (event, index, type) => {
    // assume type === Pei or Percoi
    const field = `palierAbondements${type}`;
    const paliers = this.props[field];
    if (event.target.validity.valid) {
      let value = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        value = 0;
      }
      if (type === 'percoi' && value > this.getMaxPerco()) {
        paliers[index].versementMax = this.getMaxPerco();
      } else {
        paliers[index].versementMax = value;
      }
      this.props.change(`${field}[${index}].versementMax`, value);

      if (index < nbMaxPaliers - 1 && paliers[index + 1]) {
        this.props.change(`${field}[${index + 1}].versementMin`, value);
        paliers[index + 1].versementMin = value;
      }
      this[`changerValeurSliderPlafond${type}`](this.calculerAbondementMaxDesPaliers(paliers));
    }
  };

  // TODO: replace by paliers.reduce
  calculerAbondementMaxDesPaliers = (paliers) => {
    let totalAbondementMax = 0;
    if (paliers && paliers.length === 1) {
      return paliers[0].versementMax;
    }
    for (let index in paliers) {
      let palier = paliers[index];
      if (palier.versementMax > 0) {
        totalAbondementMax += (palier.versementMax - palier.versementMin) * palier.taux;
      }
    }
    return totalAbondementMax;
  };

  changerValeurSliderPlafondPei = (value) => {
    this.props.change('plafondPei', +value);
    if (this.props.palierAbondementsPei && this.props.palierAbondementsPei.length === 1) {
      this.props.change(`palierAbondementsPei[${0}].versementMax`, value);
    }
    this.setState({
      showPlafondPassPei: value >= plafondAbondementPEE()
    });
  };

  changerValeurSliderPlafondPercoi = (value) => {
    this.props.change('plafondPercoi', value);
    if (this.props.palierAbondementsPercoi.length === 1) {
      this.props.change(`palierAbondementsPercoi[${0}].versementMax`, value);
    }
    const maxPerco = this.getMaxPerco();
    this.setState({
      showPlafondPassPercoi: value >= maxPerco
    });
  };

  changerValeurPlafondPei = (event) => {
    if (event.target.validity.valid) {
      let value = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        value = 0;
      }
      if (value > plafondAbondementPEE()) {
        value = plafondAbondementPEE();
      }

      this.props.change('plafondPei', value);
      if (this.props.palierAbondementsPei.length === 1) {
        this.props.change(`palierAbondementsPei[${0}].versementMax`, value);
      }
      this.setState({
        showPlafondPassPei: value >= plafondAbondementPEE()
      });
    }
  };

  changerValeurPlafondPercoi = (event) => {
    if (event.target.validity.valid) {
      let value = nombre(nombreSansEspaces(event.target.value));
      const maxPerco = this.getMaxPerco();
      if (event.target.value === '') {
        value = 0;
      }
      if (value > maxPerco) {
        value = maxPerco;
      }
      this.props.change('plafondPercoi', value);
      if (this.props.palierAbondementsPercoi.length === 1) {
        this.props.change(`palierAbondementsPercoi[${0}].versementMax`, value);
      }
      this.setState({
        showPlafondPassPercoi: value >= maxPerco
      });
    }
  };

  getMaxPerco = () => plafondAbondementPERCO(this.props.abnUnilateral ? this.props.plafondAbnUnilateral : undefined);

  addPalierPei = () => {
    let ligne = this.props.palierAbondementsPei.length;

    if (ligne < nbMaxPaliers) {
      let newPalier = {
        taux: 1,
        versementMin: this.props.palierAbondementsPei[ligne - 1].versementMax
      };
      this.props.change('palierAbondementsPei', [...this.props.palierAbondementsPei, newPalier]);
    }
  };

  addPalierPercoi = () => {
    let ligne = this.props.palierAbondementsPercoi.length;

    if (ligne < nbMaxPaliers) {
      let newPalier = {
        taux: 1,
        versementMin: this.props.palierAbondementsPercoi[ligne - 1].versementMax
      };
      this.props.change('palierAbondementsPercoi', [...this.props.palierAbondementsPercoi, newPalier]);
    }
  };

  deletePalierPei = () => {
    const newTauxAbondementPei = [...this.props.palierAbondementsPei];
    newTauxAbondementPei.pop();
    this.props.change('palierAbondementsPei', newTauxAbondementPei);
    this.changerValeurSliderPlafondPei(this.calculerAbondementMaxDesPaliers(newTauxAbondementPei));
  };

  deletePalierPercoi = () => {
    const newTauxAbondementPercoi = [...this.props.palierAbondementsPercoi];
    newTauxAbondementPercoi.pop();
    this.props.change('palierAbondementsPercoi', newTauxAbondementPercoi);
    this.changerValeurSliderPlafondPercoi(this.calculerAbondementMaxDesPaliers(newTauxAbondementPercoi));
  };

  //On vérifie pour chaque ligne que le taux est inférieur
  //à celui de la ligne précédente
  checkTauxDegressif = (array) => {
    if (array && array.length > 1) {
      let taux = array[0].taux;
      for (let i = 1; i < array.length; i++) {
        if (taux > array[i].taux) {
          taux = array[i].taux;
        } else {
          return true;
        }
      }
    }
    return undefined;
  };

  getPalierAbondementMaxPei = (ligne) => {
    if (this.props.palierAbondementsPei[ligne] && this.props.palierAbondementsPei[ligne].versementMax) {
      const differencePalier =
        this.props.palierAbondementsPei[ligne].versementMax - this.props.palierAbondementsPei[ligne].versementMin;
      return `soit ${differencePalier * this.props.palierAbondementsPei[ligne].taux}€ d'abond. max`;
    } else {
      return `soit XXX€ d'abond. max`;
    }
  };

  getPalierAbondementMaxPercoi = (ligne) => {
    if (this.props.palierAbondementsPercoi[ligne] && this.props.palierAbondementsPercoi[ligne].versementMax) {
      const differencePalier =
        this.props.palierAbondementsPercoi[ligne].versementMax - this.props.palierAbondementsPercoi[ligne].versementMin;
      return `soit ${differencePalier * this.props.palierAbondementsPercoi[ligne].taux}€ d'abond. max`;
    } else {
      return `soit XXX€ d'abond. max`;
    }
  };
  checkPlafondPei = () => this.calculerAbondementMaxDesPaliers(this.props.palierAbondementsPei) > plafondAbondementPEE();

  checkPlafondPercoi = () => this.calculerAbondementMaxDesPaliers(this.props.palierAbondementsPercoi) > this.getMaxPerco();

  //recupération du min via le nom parce que pas de paramètre dans la fonction de validation
  checkMaxPalierSuperieurAMin = (value, allValues, props, name) => {
    const splittedName = name.split('[');
    const index = splittedName[1][0];
    return !(parseInt(this.props[splittedName[0]][index].versementMin) < parseInt(value));
  };

  errorMaxPalierSuperieurAMin = (palier) => {
    for (let i = 0; i < palier.length; i++) {
      if (parseInt(palier[i].versementMax) <= parseInt(palier[i].versementMin)) {
        return [`Cette valeur doit être supérieure à ${palier[i].versementMin}€`];
      }
    }
    return undefined;
  };

  checkVersementVolontaireSelectionnee = (type) =>
    this.props[`sourcesVersements${type}`] && this.props[`sourcesVersements${type}`].VERSEMENT_VOLONTAIRE
      ? 'Cette modalité est applicable pour les versements volontaires, dans ce cas l’abondement doit être calculé par l’entreprise et versé exclusivement à partir de l’espace sécurisé de l’entreprise.'
      : '';

  checkSourceVersementPPVSelect = (type) => this.props[`sourcesVersements${type}`] && this.props[`sourcesVersements${type}`].PPV;

  // TODO: Remplacer cette méthode et la suivante par un fonction prenant en paramètre le type à appliquer
  checkSourceVersementPeiSelectionnee = () =>
    this.props.sourcesVersementsPei && Object.values(this.props.sourcesVersementsPei).some((source) => source)
      ? ''
      : 'Veuillez sélectionner au moins une source de versement pour le PEI';

  checkSourceVersementPercoiSelectionnee = () =>
    this.props.sourcesVersementsPercoi && Object.values(this.props.sourcesVersementsPercoi).some((source) => source)
      ? ''
      : 'Veuillez sélectionner au moins une source de versement pour le PERCOI';

  // Cette fonction permet d'enregistrer la valeur entré par l'utilisateur en cas de
  // diminution automatique suite à un dépassement du PASS
  manuallyChangePlafondPercoi = (value) => this.setState({ manualPlafondPercoi: value });

  render() {
    const { PASS, handleSubmit, pristine, invalid, submitting } = this.props;

    const classDisableOuverturePei = !this.props.ouverturePei ? 'disablePartieTunnel' : '';
    const classDisablePei = !this.props.miseEnPlaceAbondementPei ? 'disablePartieTunnel' : '';
    const classDisableOuverturePercoi = !this.props.ouverturePercoi ? 'disablePartieTunnel' : '';
    const classDisablePercoi = !this.props.miseEnPlaceAbondementPercoi ? 'disablePartieTunnel' : '';

    const palierPercoiActive = !!(this.props.palierAbondementsPercoi && this.props.palierAbondementsPercoi.length > 1);
    const palierPeiActive = !!(this.props.palierAbondementsPei && this.props.palierAbondementsPei.length > 1);

    const classDisablePeiSlider = palierPeiActive ? 'disableSlider' : '';
    const classDisablePercoiSlider = palierPercoiActive ? 'disableSlider' : '';

    if(!PASS) {
      return (
        <Box sx={styles.loaderContainer}>
          <CircularProgress className="margin_O" />
        </Box>
      );
    };
    return (
      <form className="formDispositifAbondement" onSubmit={handleSubmit}>
        <label className="titreFormulaire">
          <Trans i18nKey="dispositif.titre" />
        </label>
        <p>
          <TransHtmlText
            i18nKey="dispositif.info.simulateur.aide"
            params={{
              config: config
            }}
          />
        </p>
        <AncienneteMinimum ancienneteMinimum={this.props.ancienneteMinimum} />
        <Periodicite periodicite={this.props.periodicite} />
        <br />
        <div className="grid-margin-x alignPeiPercoiColumn">
          <AbondementPEI
            config={config}
            nbMaxPaliers={nbMaxPaliers}
            addPalierPei={this.addPalierPei}
            seniority={this.props.seniority}
            classDisablePei={classDisablePei}
            palierPeiActive={palierPeiActive}
            plafondPei={this.props.plafondPei}
            changerTauxPei={this.changerTauxPei}
            checkPlafondPei={this.checkPlafondPei}
            deletePalierPei={this.deletePalierPei}
            modeAnciennete={this.props.modeAnciennete}
            checkTauxDegressif={this.checkTauxDegressif}
            getPeiOuPercoiChecked={getPeiOuPercoiChecked}
            classDisablePeiSlider={classDisablePeiSlider}
            showPlafondPassPei={this.props.showPlafondPassPei}
            classDisableOuverturePei={classDisableOuverturePei}
            changerValeurMaxPalier={this.changerValeurMaxPalier}
            changerValeurPlafondPei={this.changerValeurPlafondPei}
            dispositifAnciennete={this.props.dispositifAnciennete}
            palierAbondementsPei={this.props.palierAbondementsPei}
            getPalierAbondementMaxPei={this.getPalierAbondementMaxPei}
            miseEnPlaceAbondementPei={this.props.miseEnPlaceAbondementPei}
            checkMaxPalierSuperieurAMin={this.checkMaxPalierSuperieurAMin}
            errorMaxPalierSuperieurAMin={this.errorMaxPalierSuperieurAMin}
            anciennete={this.props.anciennete ? this.props.anciennete.pei : {}}
            changerValeurSliderPlafondPei={this.changerValeurSliderPlafondPei}
            desactiverMiseEnPlaceAbondementPei={this.desactiverMiseEnPlaceAbondementPei}
            checkSourceVersementPeiSelectionnee={this.checkSourceVersementPeiSelectionnee}
            validateSourceVersementPeiSelectionnee={validateSourceVersementPeiSelectionnee}
            checkVersementVolontaireSelectionnee={this.checkVersementVolontaireSelectionnee}
            plafondEmptyError={this.props.genericErrors && this.props.genericErrors.plafondPei}
            PASS={PASS}
          />
          <AbondementPerCol
            config={config}
            nbMaxPaliers={nbMaxPaliers}
            seniority={this.props.seniority}
            showWarning={this.state.showWarning}
            addPalierPercoi={this.addPalierPercoi}
            classDisablePercoi={classDisablePercoi}
            palierPercoiActive={palierPercoiActive}
            abnUnilateral={this.props.abnUnilateral}
            plafondPercoi={this.props.plafondPercoi}
            changerTauxPercoi={this.changerTauxPercoi}
            modeAnciennete={this.props.modeAnciennete}
            deletePalierPercoi={this.deletePalierPercoi}
            checkTauxDegressif={this.checkTauxDegressif}
            checkPlafondPercoi={this.checkPlafondPercoi}
            getPeiOuPercoiChecked={getPeiOuPercoiChecked}
            classDisablePercoiSlider={classDisablePercoiSlider}
            changerValeurMaxPalier={this.changerValeurMaxPalier}
            plafondAbnUnilateral={this.props.plafondAbnUnilateral}
            dispositifAnciennete={this.props.dispositifAnciennete}
            showPlafondPassPercoi={this.props.showPlafondPassPercoi}
            classDisableOuverturePercoi={classDisableOuverturePercoi}
            handleManualChangePlafond={this.manuallyChangePlafondPercoi}
            changerValeurPlafondPercoi={this.changerValeurPlafondPercoi}
            palierAbondementsPercoi={this.props.palierAbondementsPercoi}
            errorMaxPalierSuperieurAMin={this.errorMaxPalierSuperieurAMin}
            checkMaxPalierSuperieurAMin={this.checkMaxPalierSuperieurAMin}
            getPalierAbondementMaxPercoi={this.getPalierAbondementMaxPercoi}
            miseEnPlaceAbondementPercoi={this.props.miseEnPlaceAbondementPercoi}
            anciennete={this.props.anciennete ? this.props.anciennete.percol : {}}
            changerValeurSliderPlafondPercoi={this.changerValeurSliderPlafondPercoi}
            checkVersementVolontaireSelectionnee={this.checkVersementVolontaireSelectionnee}
            desactiverMiseEnPlaceAbondementPercoi={this.desactiverMiseEnPlaceAbondementPercoi}
            checkSourceVersementPercoiSelectionnee={this.checkSourceVersementPercoiSelectionnee}
            validateSourceVersementPercoiSelectionnee={validateSourceVersementPercoiSelectionnee}
            plafondEmptyError={this.props.genericErrors && this.props.genericErrors.plafondPercoi}
            PASS={PASS}
          />
        </div>
        {!this.checkPeiOuPercoiChecked() && (
          <p className="errorMsgTauxDegressif">Vous devez sélectionner au moins un dispositif.</p>
        )}
        <div className="buttonCreationContainer">
          <YesButton
            text="Confirmer"
            disabled={
              invalid ||
              submitting ||
              pristine ||
              (this.props.genericErrors &&
                (this.props.genericErrors.plafondPei ||
                  this.props.genericErrors.plafondPercoi ||
                  this.props.genericErrors.periodicite))
            }
          />
        </div>
      </form>
    );
  }
}

// REDUX + REDUX FORM
const selectDispositifAbondementForm = formValueSelector('dispositifAbondementForm');

const mapStateToProps = (state) => ({
  miseEnPlaceAbondementPei: selectDispositifAbondementForm(state, 'miseEnPlaceAbondementPei'),
  miseEnPlaceAbondementPercoi: selectDispositifAbondementForm(state, 'miseEnPlaceAbondementPercoi'),
  palierAbondementsPei: selectDispositifAbondementForm(state, 'palierAbondementsPei'),
  palierAbondementsPercoi: selectDispositifAbondementForm(state, 'palierAbondementsPercoi'),
  anciennete: selectDispositifAbondementForm(state, 'anciennete'),
  ancienneteMinimum: selectDispositifAbondementForm(state, 'ancienneteMinimum'),
  ouverturePei: selectDispositifAbondementForm(state, 'ouverturePei'),
  ouverturePercoi: selectDispositifAbondementForm(state, 'ouverturePercoi'),
  plafondPei: selectDispositifAbondementForm(state, 'plafondPei'),
  plafondPercoi: selectDispositifAbondementForm(state, 'plafondPercoi'),
  abnUnilateral: selectDispositifAbondementForm(state, 'abnUnilateral'),
  periodicite: selectDispositifAbondementForm(state, 'periodicite'),
  plafondAbnUnilateral: selectDispositifAbondementForm(state, 'plafondAbnUnilateral'),
  sourcesVersementsPei: selectDispositifAbondementForm(state, 'sourcesVersementsPei'),
  sourcesVersementsPercoi: selectDispositifAbondementForm(state, 'sourcesVersementsPercoi'),
  genericErrors: getFormSyncErrors('dispositifAbondementForm')(state),
  tunnel: state.tunnel,
  PASS: state.citraData?.pass?.currentPass
});

export default connect(mapStateToProps)(reduxForm({
  form: 'dispositifAbondementForm',
  validate,
  destroyOnUnmount: false
})(DispositifAbondementForm));
